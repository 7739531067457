header {
  .navbar {
    // margin-bottom: 0px;
    // border: none;
    // // .col-xs-2 {
    // //   margin-left: -6px;
    // // }
    // .toggle-open.show, .toggle-close.show {
    //   display: inline-block !important;
    // }
    .nav-user-info {
      .dropdown-menu {
        left: calc(100% - 85px);
      }
    }
  }
}
